<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <span>Rapports</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Rapports
          </div>
        </div>
      </div>
    </div>
    <ul class="list-group rapport">
      <li class="list-group-item">
        <router-link :to="'/analyses/rapport/redevance_peage'">
          Rapport des recettes de péage
        </router-link>
      </li>

      <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_redevance_pesage'">
          Rapport des redevances de pesage
        </router-link>
      </li>
      

      <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_redevance_patente_vignette'">
          Rapport des redevances de TSU patentes Vignettes
        </router-link>
        
      </li>
      <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_recette_aire_stationnement'">
          Rapport des recettes d'aires de stationnement
        </router-link>
      </li>
      <!-- <li class="list-group-item">
        <a :href="url+'/uploads/04_FER_Rapport_SyntheseResource.docx'" 
           download>Rapport synthèse de ressouces</a>
      </li> -->
      <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_synthese_charge'">
          Rapport de synthèse de charges
        </router-link>
      </li>
      <!-- <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_patente_vignette'">
          Rapport des TSU patentes vignettes(Détails)
        </router-link>
      </li> -->
      <!-- <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_trafic_peage'">
          Rapport des détails sur les trafic des péages par gare
        </router-link>
      </li> -->
      <!-- <li class="list-group-item">
        <a :href="url+'/uploads/13_FER_Rapport_Details_Trafics_Pesage_Par_Gare.docx'" 
           download>Rapport détails sur les trafic des pesages par gare</a>
      </li>  -->

      <li class="list-group-item">
        <router-link :to="'/analyses/rapport/details_decomptes'">
          Rapport des détails décomptes
        </router-link>
      </li>
      <!-- <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_engagement_financier'">
          Rapport des engagements financiers
        </router-link>
      </li> -->
      <!-- <li class="list-group-item">
        <router-link :to="'/analyses/rapport/details_levee_fond'">
          Rapport du sommaire des dettes d'exercice
        </router-link>
      </li> -->
      <!-- <li class="list-group-item">
        <router-link :to="'/analyses/rapport/detail_peage'">
          Rapport sur les trafic des péages (Détails)
        </router-link>
      </li> -->
    </ul>
  </div>
  
 
</template>
<style>
.list-group-item{
  font-size: 0.7em;
}
</style>
<script>
export default {
  name : "Rapport",
  data: ()=>({
    url:process.env.VUE_APP_UPLOAD
  })
}
</script>